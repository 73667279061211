<template>
    <div>
        <!-- <Sidebar /> -->
        <HeaderPage />
        <div class="mt-7 pt-7 ps-3 me-2">
            <p class="ps-3 text_title">お客様の個人情報の取扱について</p>
            <div class="mt-4">
                <p class="text_paragraph">
                    （インターネットを利用したショッピングモール業務）
                </p>
            </div>
            <div class="mt-4">
                <span class="text_paragraph">
                    当社は、「個人情報の保護に関する法律」（平成15年5月30日法律第57号）に基づき、お客様の個人情報の取扱について下記のとおり公表いたします。
                </span>
            </div>
            <div class="mt-4">
                <p class="text_size">
                    1.個人情報の取得・利用について
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    当社は、お客様の個人情報を適切かつ適法な手段により取得し、下記業務において、下記利用目的の範囲内において利用させていただきます。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    業務内容
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    インターネットを利用したショッピングモール（以下ECモール）の管理運営業務
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    利用目的
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    ①サービスへのログインおよびサービスの利用（お客様の同意に基づく利用またはお客様との契約の履行のための利用<br>
                    ・お客様がサービスにログインするため<br>
                    ・お客様に適したサービスを提供・運用するため<br>
                    ・お客様と当社グループとの間の取引の成立および履行その他のお客様によるサービスの利用のため
                    ・サービスの会員であるお客様の管理のため<br>
                    ・サービスの商品等の梱包・発送業務のため<br>
                    ・サービスの対価の請求のため<br>
                    ・ポイントサービスの運用のため<br>
                    ・サービスの運営上必要な事項の通知のため<br>
                    ・サービスの各種問合わせ、アフターサービス対応のため<br>
                    ・退会したお客様へのご連絡・各種問合わせ対応のため<br>
                    ・不正行為等の防止および対応のため<br>
                    ・当社グループが実施するサービス又は企画に関する連絡のため<br>
                    ・ユーザーの本人認証及び各種画面における登録情報の自動表示<br>
                    ②広告・マーケティングのための利用（お客様の同意に基づく利用）<br>
                    ・サービス上または第三者の媒体においてサービスの勧誘、広告その他のマーケティングをするため<br>
                    ・メールマガジンの送信のため<br>
                    ・ダイレクトメールの送付のため<br>
                    ・キャンペーン、懸賞企画、アンケートの実施のため<br>
                    ・サービス上または第三者の媒体において第三者が広告主となる広告を配信するため<br>
                    ③商品開発・研究のための利用（お客様との同意に基づく利用）<br>
                    ・マーケティングデータの調査・分析のため<br>
                    ・当社グループとの新たなサービスや商品等の開発のため<br>
                    ④出店者に向けた販売促進コンサルティングのための利用<br>
                    取得する個人情報の項目 ①サービスへのログインおよび利用等に関する情報<br>
                    ・サービスログインのためのアカウントのIDおよびパスワード<br>
                    ・アカウント登録情報としての性別、生年月日、郵便番号およびメールアドレス<br>
                    ・アカウントに紐づく情報としてお客様から提供される、氏名、住所、電話番号<br>
                    ・ポイント、クーポン、その他のサービスの利用に関する情報<br>
                    ・サービスの利用を通じてお客様が行った取引の遂行のための情報および当該取引内容に関する情報<br>
                    ・電子マネー、収納代行、代引きに関する情報その他の決済およびその方法に関する情報<br>
                    ・お客様から当社へのお問い合わせやご連絡等に関する情報<br>
                    ・法律上の要請等により、本人確認を行うための本人確認書類（運転免許証、健康保険証、住民票の写し等のことをいいます。）および当該書類に含まれる情報<br>
                    ⑤お客様が使用する端末に関する情報およびCookie等を利用して取得する情報<br>
                    ・情報通信端末の機体識別に関する情報<br>
                    ・情報通信端末のOS情報<br>
                    ・インターネットへの接続に関する情報<br>
                    ・位置情報<br>
                    ・リファラー情報<br>
                    ・IPアドレス情報<br>
                    ・閲覧したURLおよび閲覧した日時に関するタイムスタンプ情報<br>
                    ・サービスの利用に関する情報<br>
                    ・お客様の端末ごとに割り振られる広告識別子情報<br>
                    ・サーバログ情報<br>
                    ⑥第三者から取得する情報<br>
                    ・お客様の同意を得て第三者から取得する情報<br>
                    ・法令に基づき取得する情報<br>
                    ・公開されていることにより取得する情報
                </p>
            </div>
            <div class="mt-3">
                <p class="text_paragraph">
                    サービスの利用に関連して当社が取得する情報の全部又は一部をご提供いただけない場合は、サービスの利用をお断りする場合がございますので、あらかじめご了承下さい。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    その他
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    ①当社は、あらかじめ利用目的を明確にして個人情報を取得いたします。また、業務の適切な運営から、お客様本人の個人情報取得に加え、法人先との取引においては法人代表者の氏名等の個人情報を取得する場合がございます。<br>
                    ②当社は、下記のとおり特定の個人情報の利用目的が法令等に基づき限定されている場合には、当該利用目的以外で利用いたしません。<br>
                    ③当社は、個人情報の保護に関する法律(以下「法」という)第2条第3項に定める要配慮個人情報ならびに労働組合への加盟、門地、本籍地、保険医療および性生活(これらのうち要配慮個人情報に該当するものを除く)に関する情報(本人、国の機関、地方公共団体、法第76条第1項各号もしくは個人情報の保護に関する法律施行規則第6条各号に掲げる者により公開されているもの、または本人を目視しもしくは撮影することにより取得するその外形上明らかなものを除く。いわゆる「機微(センシティブ)情報」)については法令等に基づく場合や適切な業務運営を確保する必要性からお客様の同意をいただいた場合等を除き、原則として取得・利用・第三者提供はいたしません。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    ＊要配慮個人情報
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    本人の人種、信条、社会的身分、病歴、犯罪の経歴、犯罪により害を被った事実その他本人に対する不当な差別、偏見その他の不利益が生じないようにその取扱いに特に配慮を要するものとして政令で定める記述等が含まれる個人情報をいう。
                </p>
            </div>
            <!-- <div class="mt-4">
                <p class="text_paragraph">
                    業務内容
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    ホームページの作成支援業務および運営、管理およびこれらに関するコンサルティング業務
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    利用目的
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    ①サービスへのログインおよびサービスの利用（お客様の同意に基づく利用またはお客様との契約の履行のための利用）<br>
                    ・お客様がサービスにログインするため<br>
                    ・お客様に適したサービスを提供・運用するため<br>
                    ・サービスの対価の請求のため<br>
                    ・サービスの運営上必要な事項の通知のため<br>
                    ・サービスの各種問合わせ、アフターサービス対応のため<br>
                    ・不正行為等の防止および対応のため<br>
                    ②広告・マーケティングのための利用（お客様の同意に基づく利用）<br>
                    ・サービス上または第三者の媒体においてサービスの勧誘、広告その他のマーケティングをするため<br>
                    ③商品開発・研究のための利用（お客様との同意に基づく利用）<br>
                    ・マーケティングデータの調査・分析のため<br>
                    ・当社グループとの新たなサービスや商品等の開発のため取得する個人情報の項目 サービスへのログインおよび利用等に関する情報<br>
                    ・サービスログインのためのアカウントのIDおよびパスワード<br>
                    ・当社の提供するホームページ環境に入力された個人情報<br>
                    ・サービスの利用、申込に際しお客様から提供される、氏名、住所、電話番号、郵便番号、メールアドレス<br>
                    ・法律上の要請等により、本人確認を行うための本人確認書類（運転免許証、健康保険証、住民票の写し等のことをいいます。）および当該書類に含まれる情報
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    その他
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    ①当社は、あらかじめ利用目的を明確にして個人情報を取得いたします。また、業務の適切な運営から、お客様本人の個人情報取得に加え、法人先との取引においては法人代表者の氏名等の個人情報を取得する場合がございます。<br>
                    ②当社は、下記のとおり特定の個人情報の利用目的が法令等に基づき限定されている場合には、当該利用目的以外で利用いたしません。<br>
                    ③当社は、個人情報の保護に関する法律(以下「法」という)第2条第3項に定める要配慮個人情報ならびに労働組合への加盟、門地、本籍地、保険医療および性生活(これらのうち要配慮個人情報に該当するものを除く)に関する情報(本人、国の機関、地方公共団体、法第76条第1項各号もしくは個人情報の保護に関する法律施行規則第6条各号に掲げる者により公開されているもの、または本人を目視しもしくは撮影することにより取得するその外形上明らかなものを除く。いわゆる「機微(センシティブ)情報」)については法令等に基づく場合や適切な業務運営を確保する必要性からお客様の同意をいただいた場合等を除き、原則として取得・利用・第三者提供はいたしません。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    ＊要配慮個人情報
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    本人の人種、信条、社会的身分、病歴、犯罪の経歴、犯罪により害を被った事実その他本人に対する不当な差別、偏見その他の不利益が生じないようにその取扱いに特に配慮を要するものとして政令で定める記述等が含まれる個人情報をいう。
                </p>
            </div> -->
            <div class="mt-4">
                <p class="text_size">
                    2.個人データの第三者への提供について
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    当社は、法令等により認められる場合を除き、あらかじめお客様の同意をいただくことなくお客様の個人データを第三者に提供することはありません。個人情報保護に関する法律第23条第1項によりあらかじめお客様の同意をいただくことなく第三者提供が認められる事項は下記の事項です。
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    ①法令に基づく場合
                    ②人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br>
                    ③公衆衛生の向上または児童の健全な育成の推進のため特に必要がある場合であって本人の同意を得ることが困難であるとき<br>
                    ④国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがあるとき
                </p>
            </div>
            <div class="mt-4">
                <p class="text_size">
                    3.保有個人データの開示等の手続きについて
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    申出先
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    当社窓口にお申し出ください。郵送による請求をご希望の場合は、手続関係書類を送付させていただきますので、電話等でお問い合わせください。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    手続きの種類
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    ①利用目的の通知の求め<br>
                    ②開示の求め<br>
                    ③内容の訂正、追加または削除の求め<br>
                    ④利用停止または消去の求めおよび第三者への提供停止の求め
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    請求が可能な方　本人様または代理権が確認できる代理人様とする。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    提出書類
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    ご提出していただく書類は、ご請求の方法等により異なります。当社窓口にお申し出ください。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    回答の方法
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    本人様ご来店または本人様宛郵便にて文書で回答させていただきます。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    手数料
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    ①当社が定める基本事項を求められる場合は1回当たり540円の手数料を現金にて申受けます。また、郵送の場合は別途郵送料を申受けます。<br>
                    ②詳しくは、当社窓口にてご確認ください。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    回答期間
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    受付した日より起算して原則10日以内を目途に対応させていただきます。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    請求に応じられない場合
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    下記に定める場合等は請求に応じられないことがありますが、手数料はご返却できませんので、あらかじめご了承ください。
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    ①開示の求めの対象が「保有個人データ」に該当しない場合<br>
                    ②本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br>
                    ③当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br>
                    ④他の法令等に違反することとなる場合
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    その他開示等の手続について、ご不明な点がございましたら当社窓口にお申し出ください。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_size">
                    4.当社は、以下のとおり個人情報を共同利用します。
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    ①共同して利用される個人情報の項目<br>
                    第1条に記載した項目<br>
                    ②共同して利用する者の範囲<br>
                    ・ECモールの加盟店<br>
                    ・配送事業者<br>
                    ③利用する者の利用目的<br>
                    第1条に記載した利用目的<br>
                    ④共同利用する個人情報の管理について責任を有する者<br>
                    株式会社トーカイ薬局
                </p>
            </div>
            <div class="mt-4">
                <p class="text_size">
                    5.個人情報の取扱に関する苦情等受付の窓口について
                </p>
            </div>
            <div class="mt-4">
                <p class="text_paragraph">
                    当社は、個人情報の取扱に関する苦情等の問合せに適切かつ迅速に対応するため、下記窓口を設置しておりますので、ご意見、ご要望等がございましたらお申し出ください。<br>
                    誠意をもって対応させていただきます。
                </p>
            </div>
            <div class="mt-2">
                <p class="text_paragraph">
                    株式会社トーカイ薬局EC事業部<br>
                    電話番号: 0570-030-305<br>
                    e-mail: info@tajimingo.com
                </p>
            </div>
            <!-- <v-fab-transition>
                <v-btn
                v-scroll="onScroll"
                v-show="fab"
                class="mx-2"
                fab
                dark
                small
                fixed
                bottom
                right
                style="height: 50px; width: 50px"
                color="#ff0090"
                @click="toTop"
                >
                <v-icon dark size="40">
                    mdi-chevron-up
                </v-icon>
                </v-btn>
            </v-fab-transition> -->
        </div>
        <div class="mt-7" style="margin-bottom: 8rem">
            <v-card class="d-flex justify-center" flat>
                <v-btn @click.prevent="$router.push('/')" color="#ff0090" depressed class="white--text">
                {{ $t("message.go-shopping") }}
                </v-btn>
            </v-card>
            <CategoryFooter class="mb-0 p-0" />
        </div>
        <Sidebar />
    </div>
</template>

<script>
import Sidebar from '../../components/developmentv2/Sidebar.vue'
import HeaderPage from '../../components/developmentv2/HeaderPage.vue'
import CategoryFooter from '../../components/CategoryFooter.vue'
export default {
    name: "PrivacyData",
    data() {
        return {
            // fab: false
        }
    },
    components: {
        Sidebar,
        CategoryFooter,
        HeaderPage
    },
    beforeMount(){
        window.scrollTo(0,0)
    },
    methods: {
        // onScroll (e) {
        //     if (typeof window === 'undefined') return
        //     const top = window.pageYOffset ||   e.target.scrollTop || 0
        //     // const bottom = window.pageYOffset ||   e.target.scrollTop || 0
        //     // (window.innerHeight + window.scrollY) >= document.body.offsetHeight
        //     // console.log(bottom);
        //     this.fab = top > 20
        // },
        //     toTop () {
        //     this.$vuetify.goTo(0)
        //     // window.scrollTo({
        //     //   top: document.body.scrollHeight,
        //     //   behavior: "smooth"
        //     // })
        // }
    }
}
</script>

<style>
.text_title {
  border-left: 10px solid #ff0090;
  font-size: 18px;
}
.text_size {
    font-size: 14px;
}
.text_paragraph {
    white-space: pre-line;
    font-size: 12px;
}
</style>